import React, { useState } from "react"
// import axios from 'axios'
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import { Helmet } from 'react-helmet'
import CustomBlogBanner from "../components/blog-banner"
import BlogCard from "../components/blog-card"
import BlogPageDetails from "../components/blog-page-details"
import Moment from "react-moment"

const AuthorPage = ({ data }) => {
  const [visiblePosts, setVisiblePosts] = useState(30)

  const [numberOfPosts, setNumberOfPosts] = useState(
    data.gcms.author.blogPost.length
  )

  const loadMore = () => {
    setVisiblePosts(prevState => prevState + 30)
  }

  const blogPostsArray = data.gcms.author.blogPost

  blogPostsArray.sort((a, b) => {
    var c = new Date(a.publicationDate)
    var d = new Date(b.publicationDate)

    return d - c
  })

  return (
    <Layout>
      <SEO
        title={data.gcms.author.name + " | The Billsby Blog"}
        description={
          "Read the latest posts from" +
          data.gcms.author.name +
          "on The Billsby Blog for news and insights on subscription billing and recurring payments."
        }
        url={"https://www.billsby.com/author/" + data.gcms.author.slug}
      />

      <CustomBlogBanner />

      <BlogPageDetails
        blogPageType="blogAuthor"
        authorImage={data.gcms.author.profilePicture.url}
        authorName={data.gcms.author.name}
      />

      <div className="section-blog-list">
        <div className="container">
          <div className="list-wrapper">
            {blogPostsArray.slice(0, visiblePosts).map(post => {
              const date = new Date(post.publicationDate)

              const appendLeadingZeroes = n => {
                if (n <= 9) {
                  return "0" + n
                }
                return n
              }

              const formattedDate =
                date.getFullYear() +
                "/" +
                appendLeadingZeroes(date.getMonth() + 1) +
                "/" +
                appendLeadingZeroes(date.getDate())

              var url = formattedDate + "/" + post.slug
              var cat =
                post.blogCategory !== null
                  ? post.blogCategory.categoryName
                  : null
              var catSlug =
                post.blogCategory !== null ? post.blogCategory.slug : null

              return (
                <BlogCard
                  // featuredImage={post.featuredImage.url}
                  key={post.id}
                  image={post.image}
                  category={cat}
                  categorySlug={catSlug}
                  title={post.title}
                  url={url}
                  date={
                    <Moment format="MMMM DD YYYY">
                      {post.publicationDate}
                    </Moment>
                  }
                />
              )
            })}
          </div>
          <div className="btn-wrapper">
            {visiblePosts < numberOfPosts && (
              <a className="btn-blue" onClick={loadMore}>
                Load more posts
              </a>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AuthorPage

export const query = graphql`
  query AuthorTemplate($authorId: ID!) {
    gcms {
      author(where: { id: $authorId }) {
        profilePicture {
          url
        }
        name
        slug
        blogPost {
          id
          title
          slug
          blogCategory {
            slug
            categoryName
          }
          image: featuredImage {
            handle
            width
            height
          }
          publicationDate
        }
      }
    }
  }
`
